<script setup>
import { ref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import loadercomp from "@/components/loadercomp";
import errorComp from "@/components/errormessage.vue";
import { useStore } from "vuex";
import getCookie from "@/scripts/utils/getCookie.js";
import router from "@/router";
import { useRoute } from "vue-router";
const store = useStore();
const route = useRoute();
const country = ref("US"),
    region = ref("CA");
let stripe_key = process.env.GATSBY_STRIPE_KEY || "pk_test_u9AUIlg16rFBjt4dU4Xv0uq300X0mELXdY";
let stripe = window.Stripe(stripe_key),
    elements = stripe.elements(),
    card = null,
    name = ref(null),
    email = ref(null),
    street = ref(null),
    city = ref(null);

onMounted(() => {
    card = elements.create("card", {
        style: {
            base: {
                iconColor: "#91a0df",
                fontSize: "16px",
                fontFamily: "inherit",
                color: "inherit",
            },
        },
    });
    card.mount("#payment-element");
});

const addBillingDetails = async (token, email) => {
    const response = await fetch(`/api/org/${route.query.org}/billing`, {
        method: "POST",
        body: JSON.stringify({
            email: email,
            stripe_token: token,
        }),
        headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": getCookie("csrftoken"),
        },
    });

    const data = await response.json();
    store.state.loader = !store.state.loader;
    if (data.ok) {
        router.push({ name: "Update plan" });
    } else {
        switch (data.error) {
            case "stripe-token-missing":
                store.state.errMsg = "Unknown error processing payment information. Please try again.";
                break;
            case "stripe-missing-payment":
                store.state.errMsg = "The payment method you have entered is not supported. Please try another method.";
                break;
            case "stripe-checks-failed":
                store.state.errMsg = "Your payment method could not be authorized. Please check the information entered";
                break;
            default:
                store.state.errMsg = "An unknown error has occurred. Please try again.";
                break;
        }
    }
};

const handleSubmit = () => {
    store.state.loader = true;
    stripe
        .createToken(card, {
            name: name.value,
            address_city: city.value,
            address_line1: street.value,
            address_country: country.value,
            address_state: region.value,
        })
        .then((result) => {
            if (result.error) {
                store.state.loader = false;
                store.state.errMsg = result.error.message;
            } else {
                addBillingDetails(result.token.id, email.value);
            }
        });
};
</script>

<template>
    <form @submit.prevent="handleSubmit" class="buidl_wrap flex_col">
        <loadercomp v-if="$store.state.loader" />
        <errorComp :errMsg="$store.state.errMsg" />
        <div class="user_data">
            <label for="name">Name on Card</label>
            <input required v-model="name" type="text" id="name" placeholder="Brad Joseph Grant" />
        </div>
        <div class="user_data">
            <label for="email">Email</label>
            <input required type="email" v-model="email" id="email" placeholder="Bradjoseph@gmail.com" />
        </div>
        <div class="user_data">
            <label for="street">Billing Street Address</label>
            <input required type="text" v-model="street" id="street" placeholder="846 Marta Mission Apt. 218..." />
        </div>
        <div class="user_data">
            <label for="city">City</label>
            <input required type="text" v-model="city" id="city" placeholder="Hillfort" />
        </div>

        <div class="flex country_region">
            <div class="flex_col">
                <label for="country">Country</label>
                <country-select v-model="country" id="country" :country="country" :autocomplete="true" topCountry="US" />
            </div>
            <div class="flex_col">
                <label for="region">Region</label>
                <region-select v-model="region" id="region" :country="country" :autocomplete="true" :region="region" />
            </div>
        </div>
        <div id="payment-element"></div>

        <div class="btn_color">
            <button type="submit">Submit</button>
        </div>
    </form>
</template>

<style lang="scss" scoped>
form {
    width: 40rem;
    margin: 0 auto;
    gap: 1rem;
    box-shadow: 1px 1px 14px 5px #e7e7e7;
    padding: 2rem 2rem;
    border-radius: 10px;
    @include media("<=tablet") {
        width: 30rem;
    }
    @include media("<=phone-tab") {
        width: 100%;
    }
    .user_data {
        @include flex_col(0.4em);
    }
    #payment-element {
        background: $fadegrey;
        padding: 15px 10px;
    }
    .country_region {
        & > div {
            width: 50%;
            gap: 0.4em;
        }
    }
    input {
        padding: 15px 10px;
        width: 100%;
    }
    select {
        padding: 10px 0px;
        color: rgb(39, 35, 35);
        background: $fadegrey;
        width: 100%;
    }
    button {
        width: 100%;
    }
}
</style>
